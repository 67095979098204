<template>
  <el-row class="itembox">
    <!--  v-if="goodsList.payableAmount<Number(goodsList.goodsCount)*0.01" -->
    <div class="item" :span="24">
      <el-col :span="2"><div class="back" @click="jumpBack">返回</div></el-col>
      <el-col :span="18">
        <div class="info">
          <span class="gooodNumBox"
            >共<span class="goodsNum">{{ goodsList.goodsCount }}</span
            >件商品</span
          >
          <div class="MsgBox">
            <div class="MSGLineO">
              <span>应付药豆</span>
              <span class="amountPayable">
                <strong>{{goodsList.payablePoints}}药豆</strong>
              </span>
            
            </div>
            <div class="MSGLineT" v-if="Number(goodsList.goodMoney)>0 &&goodsList.payType!== 1">
              <span>还需付金额</span>
              <span class="amountPayable">
                <strong>{{goodsList.payableAmount | capitalize}}</strong>
              </span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="orderAmoutBtn" v-if="Number(this.goodsList.goodMoney)>0" @click="jumpOrderSumbit">
          订单结算
        </div>
        
        <div class="orderAmoutBtn" v-else @click.once="jumpOrderSumbit">
          订单结算
        </div>
      </el-col>
    </div>
    

  <!-- 还款  start -->
    <el-dialog
      id="PayBox"
      title="选择支付方式"
      :visible.sync="dialogPayBoxVisible"
      width="600px"
      height="200px"
    >
    <el-row class="content">
      <el-col class="wraper">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <el-form-item label="支付金额">
            <el-input v-model="goodsList.payableAmount" disabled></el-input> 元
          </el-form-item>
          <el-form-item label="支付方式">
            <el-radio-group v-model="ruleForm.resource" size="medium">
              <el-col
                :span="8"
                v-for="(item, index) in imgArr"
                :key="index"
                :class="
                  index === imgArrSelect ? 'imgWraper active' : 'imgWraper'
                "
                @click.native="changeFun(item, index)"
              >
                <i class="el-icon-success" aria-hidden="true"></i
                ><img :src="item.img" :alt="item.alt" />
              </el-col>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
      <div class="clear"></div>
    </el-dialog>
        <!-- 支付二维码弹框 -->
    <el-dialog
      title="扫码充值"
      id="QRCodeBox"
      center
      :visible.sync="DialogShowQRCode"
      width="300px"
      height="300px"
      @close="closeDialog"
    >
      <vue-qr
        :bgSrc="config.logo"
        :logoSrc="config.logo"
        :text="config.value"
        :size="200"
      ></vue-qr>
    </el-dialog>
    <!-- 还款  end -->


  </el-row>
</template>

<script>
// import Qs from "qs";
// import moment from "moment";
import VueQr from "vue-qr";
// import {IntegralMallSubmit,IntegralMallPay,IntegralMallMoneyPay} from "api/carToOrder.js";
import {IntegralMallPay,IntegralMallMoneyPay} from "api/carToOrder.js";
import { PointsOrderDetail} from "api/order.js";
export default {
  name: "AmountBottom",
  data() {
    return {
      payableAmount: "",
       dialogPayBoxVisible:false,
      config: {
        value: "", //显示的值、跳转的地址
        imagePath: require("@/assets/logo.png"), //中间logo的地址
      },
      ruleForm: {
        resource:1,
      },
      rules: {
        resource: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
      },
        imgArr: [
        {
          img:
            "http://image.demo.b2b2c.shopxx.net/6.0/16d9ceaf-ada6-480d-95c3-6244eac4b0ee.png",
          alt: "微信支付",
          num: 0,
        },
        {
          img:
            "http://image.demo.b2b2c.shopxx.net/6.0/aa348738-a710-4330-915d-a2d2475681e6.png",
          alt: "支付宝",
          num: 1,
        },
      ],
      imgArrSelect: 0,

      DialogShowQRCode: false,
    };
  },
  props: {
    goodsList: {
      type: Object,
    },
  },
  created() {
    console.log('药豆00',this.goodsList)
  },
  computed: {
    
  },
  components: {
    VueQr,
  },
  methods: {
    jumpBack() {
      this.$router.push({ name: "Cart" });
    },
    
        // 确定还款
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
              if (valid) {
                if(sessionStorage.getItem('billQRCode')==null||sessionStorage.getItem('billQRCode')==''){
                  let datas = {
                    buyChannel: 1,   // 1：web，2：app
                    id:this.goodsList.id,
                    paymentMethod:this.ruleForm.resource, //支付方式（1：微信，2：支付宝）

                    consigneeAddress:this.goodsList.address,//	收货地址			
                    consigneeName:this.goodsList.consigneeName,//	收货人姓名			
                    consigneeTel:this.goodsList.consigneeTel,//	收货人电话
                  };
                  IntegralMallMoneyPay(datas).then((res) => {
                    console.log(res)
                      if (res.data.code == 200) {
                        //获取支付链接  生成二维码
                        this.DialogShowQRCode = true;
                        console.log("生成二维码",res.data.data.billQRCode)
                        sessionStorage.setItem('billQRCode',res.data.data.billQRCode)
                        this.config.value = res.data.data.billQRCode;
                        this.dialogPayBoxVisible=false
                      }
                  });
                }else{
                   this.DialogShowQRCode = true;
                      this.config.value =sessionStorage.getItem('billQRCode');
                        this.dialogPayBoxVisible=false
                }

              } else {
                return false;
              }
      });
    },
    //   选择支付方式
    changeFun(item, index) {
      this.imgArrSelect = index;
      this.ruleForm.resource = item.num+1;
    },
    closeDialog() {
      this.$confirm("请根据您的支付情况点击下面的按钮", "请确认支付是否完成", {
        confirmButtonText: "已完成支付",
        cancelButtonText: "支付遇到问题",
        type: "warning",
      })
        .then(() => {
         // 跳转支付成功页面
          // 去判断是否药豆订单支付成功
          let datas={
            id:this.goodsList.id
          }
          // status  1.待支付,2.待发货,3.待收货,4.已完成,5.已取消
          // status==2||status==3||status==4  时是已经支付状态
         PointsOrderDetail(datas).then((data)=>{
             console.log(4566,data.data.data.status)
          if(data.data.data.status==2||data.data.data.status==3||data.data.data.status==4 ){//订单为待发货状态
              
                // let datas = {
                //   sn: sessionStorage.getItem("orderId"),
                //   total: this.total,
                //   creatTime: moment(this.creatTime).format("YYYY-MM-DD a hh:mm:ss"),
                // };
                // sessionStorage.setItem("orderDatas", Qs.stringify(datas));
                this.$message({
                  message: data.data.msg,
                  type: 'success'
                });
                sessionStorage.removeItem('billQRCode')
                // 支付成功跳转我的订单页
                this.$router.push({name:"MyOrder"})

           }else{
              this.$message({
                message:'您未支付成功该订单',
                type: "warning",
              });
           }
         })
        })
        .catch(() => {
          // 支付不成功，关闭当前弹框，停留当前页面
          // this.$router.push({ path: "/home" });

          // 去判断是否药豆订单支付成功
          let datas={
            id:this.goodsList.id
          }
          
         PointsOrderDetail(datas).then((data)=>{
           console.log(123,data.data)
           if(data.data.data.status==2||data.data.data.status==3||data.data.data.status==4 ){//订单为待发货状态
                // let datas = {
                //   sn: sessionStorage.getItem("orderId"),
                //   total: this.total,
                //   creatTime: moment(this.creatTime).format("YYYY-MM-DD a hh:mm:ss"),
                // };
                this.$message({
                  message: data.data.msg,
                  type: 'success'
                });
                 sessionStorage.removeItem('billQRCode')
                // 支付成功跳转我的订单页
              this.$router.push({name:"MyOrder"})

           }else{
              this.$message({
                message:'您未支付成功该订单',
                type: "warning",
              });
           }
         })
          
        });
    },
    jumpOrderSumbit() {

      if(Number(this.goodsList.goodMoney)>0){
        // 跳支付选择弹窗
        // alert('跳支付选择弹窗')
        this.dialogPayBoxVisible=true

      }else{
          // 订单提交接口
          // goodsCount和pointsRedeemProductId必传--实体类商品时候需传：consigneeName+consigneeTel+consigneeAddress
          // alert(this.goodsList.consigneeName)
          // let datas={
          //         goodsCount:this.goodsList.goodsCount,
          //         pointsRedeemProductId:this.goodsList.pointsRedeemProductId
          //       }
          // IntegralMallSubmit(datas).then(res=>{
          //         let Paydatas={
          //             id:res.data.data.id
          //           }
           let Paydatas={
                    id:this.$route.query.id,
                      // id:res.data.data.id,
                    consigneeAddress:this.goodsList.address,//	收货地址			
                    consigneeName:this.goodsList.consigneeName,//	收货人姓名			
                    consigneeTel:this.goodsList.consigneeTel,//	收货人电话
                  }
                  // 订单支付接口
                  IntegralMallPay(Paydatas).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                          message: res.data.msg,
                          type: 'success'
                        });
                        // 支付成功跳转我的订单页
                      this.$router.push({name:"MyOrder"})
                    }else{
                        this.$message({
                          message: res.data.msg,
                          type: 'warning'
                        });
                    }
                  
                  // })
          })
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
#QRCodeBox{
/deep/.el-dialog__body {
    padding: 0px 25px 10px !important;
    img {
      display: block !important;
      margin: 0 auto;
    }
  }
}
.el-input {
      width: 110px;
    }
.wraper {
      display: flex;
      align-items: center;
      .el-form-item {
        padding-top: 20px;
        .imgWraper {
          width: 200px;
          height: 50px;
          border: 1px solid #dddddd;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          margin-left: 20px;
          padding: 0 50px;
          img {
            display: inline-block;
          }
          .el-icon-success {
            color: #cccccc;
            font-size: 28px;
          }
          &.active {
            border: 1px solid #ff3333;
            .el-icon-success {
              color: #ff3333;
            }
          }
        }
        .imgWraper:first-child {
          margin-left: 0px;
        }
      }
    }
.itembox {
  padding: 0 20px;
}
.item {
  // margin-top:20px;
  width: 100%;
  line-height: 60px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .back {
    height: 100%;
    display: block;
    text-align: center;
    border-right: 1px solid #eeeeee;
    color: #3a3838;
    font-size: 12px;
    &:hover {
      color: #d53a3b;
    }
  }
  .info {
    // width:  calc(100% - 40px);
    width: 100%;
    font-size: 12px;
    text-align: right;
    color: #666;
    .gooodNumBox {
      padding-right: 30px;
      .goodsNum {
        color: @theme;
        font-weight: 600;
        padding: 0 3px;
      }
    }
    .MsgBox {
      float: right;
      width: 225px;
      margin-right: -20px;
      .MSGLineO {
        margin-top: 8px;
        line-height: 22px;
        float: left;
        width: 100%;
        text-align: left;
        span:nth-child(1) {
          width: 80px;
          text-align: right;
          display: inline-block;
          color: #333;
        }
      }
      .MSGLineT {
        line-height: 22px;
        float: left;
        width: 100%;
        text-align: left;
        span:nth-child(1) {
          width: 80px;
          text-align: right;
          display: inline-block;
          color: #333;
        }
        
      }
    }

    .amountPayable {
      color: @theme;
      font-size: 18px;
      font-weight: 600;
      padding-right: 20px;
      padding-left: 10px;
    }
  }
}

.orderAmoutBtn {
  float: right;
  background: @theme;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  width: 174px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  opacity: 0.8;
  -webkit-transition: opacity 0.3s, background-color 0.3s;
  transition: opacity 0.3s, background-color 0.3s;
  cursor: pointer;
}
.minimumAmountBox {
  width: 100%;
  background: #fdfdfd;
  color: #f33;
  line-height: 40px;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
}
</style>
